<template>
  <el-card class="box-card">
    <el-table :data="list">
      <el-table-column
          align="center"
          prop="user.name"
          label="用户">
      </el-table-column>
      <el-table-column align="center" label="发起砍价时间">
        <template slot-scope="scope">
          <div>{{ scope.row.create_at | formatDate }}</div>
        </template>
      </el-table-column>
      <el-table-column align="center" label="最少砍价数 / 已砍人数" show-overflow-tooltip width="200px">
        <template #default="{ row }">
          <div>{{ row.chop_number }} / {{ row.succeed_number }}</div>
        </template>
      </el-table-column>
      <el-table-column
          align="center"
          prop="sku_info.price"
          label="底价">
      </el-table-column>
      <el-table-column align="center" prop="end_time" label="结束时间">
        <!--        <template slot-scope="scope">
                  <div>{{ scope.row.end_ | formatDate }}</div>
                </template>-->
      </el-table-column>
      <el-table-column
          align="center"
          prop="state"
          label="状态">
        <template slot-scope="scope">
          <span v-if="scope.row.state == 1">进行中</span>
          <span v-if="scope.row.state == 2">成功</span>
          <span v-else>失败</span>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
        background
        :current-page="queryForm.page"
        :layout="layout"
        :page-size="queryForm.pageSize"
        :total="count"
        @current-change="handleCurrentChange"
        @size-change="handleSizeChange"
    />
  </el-card>
</template>
<script>
import {formatDate} from "@/utils/theTimeStamp";
import Edit from './components/Edit'
import {apiGetBargainingList} from "@/request/api";

export default {
  // 时间戳转换
  filters: {
    formatDate(time) {
      time = time * 1000
      let date = new Date(time)
      // console.log(new Date(time))
      return formatDate(date, 'yyyy-MM-dd hh:mm:ss')
    },
  },
  props: [],
  data() {
    return {
      selectRows: '',
      // listLoading: true,
      layout: 'total, sizes, prev, pager, next, jumper',
      count: 0,
      queryForm: {
        page: 1,
        pageSize: 10,
        id: this.$route.query.id,
      },
      list: [],
    }
  },
  computed: {},
  watch: {},
  created() {
    this.fetchData()
  },
  methods: {
    setSelectRows(val) {
      this.selectRows = val
    },
    handleSizeChange(val) {
      this.queryForm.pageSize = val
      this.fetchData()
    },
    handleCurrentChange(val) {
      this.queryForm.page = val
      this.fetchData()
    },
    queryData() {
      this.queryForm.page = 1
      this.fetchData()
    },
    async fetchData() {
      this.listLoading = true
      apiGetBargainingList(this.queryForm).then(res => {
        if (res.code == 200) {
          this.list = res.data.list
          this.count = res.data.count
        }
      })
      this.listLoading = false
    },
    // 状态
    formatterState: function (row) {
      return row.state == '0' ? '未开始' : row.state == '1' ? '进行中' : row.state == '2' ? '已结束' : row.state == '3' ? '已关闭' : '暂无'
    },
  }
}

</script>
